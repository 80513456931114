





































































interface Account {
    bank: string;
    accountNumber: string;
    cci: string;
}
import { Component, Prop, Vue, PropSync, Watch } from 'vue-property-decorator';

@Component
export default class AccountInput extends Vue {
    @Prop() value!: Account;
    @Prop() currency!: string;
    @Prop() relationship!: boolean;
    @PropSync('empty', { required: true }) isEmpty!: boolean;

    customMessages = {
        numeric: `El campo cuenta en ${this.currency} debe contener solo caracteres numéricos`
    };

    banks = [
        {
            value: 'BCP',
            label: 'BCP'
        },
        {
            value: 'BBVA',
            label: 'BBVA'
        },
        {
            value: 'INTERBANK',
            label: 'INTERBANK'
        },
        {
            value: 'SCOTIABANK',
            label: 'SCOTIABANK'
        },
        {
            value: 'BAN-BIF',
            label: 'BAN BIF'
        },
        {
            value: 'BANCO-PICHINCHA',
            label: 'BANCO PICHINCHA'
        },
        {
            value: 'GNB',
            label: 'GNB'
        },
        {
            value: 'BANCO-DE-LA-NACION',
            label: 'BANCO DE LA NACIÓN'
        },
        {
            value: 'MI-BANCO',
            label: 'MI BANCO'
        },
        {
            value: 'BANCO-COMERCIO',
            label: 'BANCO COMERCIO'
        },
        {
            value: 'OTRO',
            label: 'OTRO'
        }
    ];

    @Watch('value', {
        immediate: true,
        deep: true
    })
    checkIfItIsEmpty() {
        const isEmpty =
            (this.currentValue.cci === undefined ||
                this.currentValue.cci.length < 1) &&
            (this.currentValue.accountNumber === undefined ||
                this.currentValue.accountNumber.length < 1) &&
            (this.currentValue.bank === undefined ||
                this.currentValue.bank.length < 1);

        if (isEmpty !== this.isEmpty) {
            this.isEmpty = isEmpty;
        }
    }

    get required() {
        return !this.isEmpty || this.relationship;
    }

    get currentValue() {
        return this.value;
    }

    set currentValue(value: Account) {
        this.$emit('input', value);
    }
}
