




















































































































































































































































































































































































































































































































































































































































































































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { agentModule } from '@/store/modules/agent';
import { membershipRequestModule } from '@/store/modules/membership-request';
import AutoComplete from '@/components/AutoComplete.vue';
import BankDetail from '@/components/BankDetail.vue';
import FormAddress from '@/components/FormAddress.vue';
import CropperUpload from '@/components/CropperUpload.vue';
import Swal from 'sweetalert2';

@Component({
    components: { CropperUpload, AutoComplete, FormAddress, BankDetail }
})
export default class CompanyData extends Vue {
    $refs!: any;

    redirectTo: string | null = null;
    brokerSelect: any = {};
    validSelect = true;
    rucIsNull = false;
    corporateNameIsNul = false;

    dialogAsociado = {
        step: 1,
        visible: false
    };

    dialogDesafiliacion = {
        step: 1,
        visible: false
    };

    form: any = {
        ruc: undefined,
        logo: undefined,
        datosRemitente: '',
        corporateName: '',
        accountNumber: '',
        accounts: [{ currency: 1 }, { currency: 2 }],
        mvcs_pj: '',
        ruc_broker: '',
        corporateName_broker: '',
        fiscal_address: '',
        follow_email: '',
        departamentoId: 15,
        provinciaId: 1501,
        distritoId: undefined,
        urbanizacionId: undefined,
        direccion: undefined,
        interior: undefined,
        role: this.user && this.user.account ? this.user.account.rol : null
    };

    statusMemberShip = -1;
    memberShipRequest: any = {};

    get personalDataIsMissing() {
        return agentModule.personalDataIsMissing;
    }

    async validateMVCS() {
        const data = await agentModule.validateInformationBroker(
            this.form.mvcs_pj
        );
        if (data.status) {
            if (data.corporateName || data.corporateName !== '-') {
                this.corporateNameIsNul = false;
                this.form.corporateName_broker = data.corporateName;
            } else {
                this.corporateNameIsNul = true;
            }

            this.form.fiscal_address = data.fiscal_address;
            if (parseInt(data.ruc_broker)) {
                this.rucIsNull = false;
                this.form.ruc_broker = data.ruc_broker;
            } else {
                this.form.ruc_broker = '';
                this.rucIsNull = true;
            }
        } else {
            this.form.corporateName_broker = '';
            this.form.fiscal_address = '';
            this.form.ruc_broker = '';
            this.$notify.error({
                title: 'Error',
                message: 'Los datos ingresados no son válidos'
            });
        }
    }

    async store() {
        try {
            await agentModule.editAgent(this.form);
            Swal.fire({
                title: 'Éxito',
                text: 'Se actualizaron los datos correctamente.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false,
                width: '480px'
            }).then(() => {
                if (this.redirectTo) {
                    this.$router.push({ name: this.redirectTo });
                }
            });
        } catch (error) {
            if (error.errors) {
                this.$refs.provider.applyResult({
                    errors: ['Este ruc ya se encuentra registrado.'],
                    valid: false,
                    failedRules: {}
                });
            } else {
                this.$notify.error({
                    title: 'Error',
                    message:
                        typeof error.mensaje === 'string'
                            ? error.mensaje
                            : 'Hemos tenido algunos inconvenientes'
                });
            }
        }
    }

    async upload(form: FormData) {
        form.append('key', 'logo');
        await agentModule.uploadFile(form);
        this.form.logo = agentModule.agent?.logo;
    }

    get informationIsMissing() {
        return agentModule.informationIsMissing;
    }

    @Watch('informationIsMissing', { deep: true, immediate: true }) redirect(
        value: boolean,
        oldValue: boolean
    ) {
        if (oldValue || value) {
            this.redirectTo = 'MissingInformation';
        }
    }

    get rol() {
        return agentModule.rol;
    }

    get user() {
        return agentModule.agent;
    }

    getBrokers(queryString: any, cb: Function) {
        agentModule.getBrokers(queryString).then((data) => {
            const preprocess = data.map((element: any) => ({
                ...element,
                ...element.data_billing
            }));
            cb(preprocess);
        });
    }

    async selectBroker(broker: any) {
        this.validSelect = false;
        this.brokerSelect = broker;
    }

    async openModalConfirmMembershipRequest() {
        this.dialogAsociado.visible = true;
    }

    modalRemoveMemberShip() {
        this.dialogDesafiliacion.visible = true;
    }

    async sendMembershipRequest() {
        try {
            await membershipRequestModule.send({
                to: this.brokerSelect.agenteId
            });
            if (agentModule.agent && !agentModule.agent.account._id) {
                await agentModule.saveAccount({
                    rol: agentModule.agent.account.rol
                });
            }
            this.dialogAsociado.step++;
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }

    async removeMembershipRequest() {
        try {
            await membershipRequestModule.remove({
                _id: this.memberShipRequest._id
            });
            if (agentModule.agent && agentModule.agent.memberShipRequest) {
                agentModule.setAgent({
                    memberShipRequest: agentModule.agent.memberShipRequest.map(
                        (request, index) => {
                            if (
                                agentModule.agent &&
                                index ===
                                    agentModule.agent.memberShipRequest.length -
                                        1
                            ) {
                                request.status = 6;
                            }
                            return request;
                        }
                    )
                });
            }
            this.statusMemberShip = -1;
            this.dialogDesafiliacion.step = 2;
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }

    async accept() {
        this.dialogAsociado.visible = false;
        await this.asociado();
    }

    async acceptDesafiliado() {
        this.dialogDesafiliacion.visible = false;
        await this.asociado();
    }

    async mounted() {
        if (
            this.rol === 'broker' &&
            this.user &&
            this.user.account &&
            this.user.account.data_billing
        ) {
            this.form.logo = this.user.logo;
            this.form.datosRemitente = this.user.datosRemitente;
            this.form.accountNumber = this.user.accountNumber;
            if (this.user.accounts && this.user.accounts.length > 0) {
                this.form.accounts = this.user.accounts;
            }
            (this.form.mvcs_pj = this.user.account.data_billing.MVCSJ),
                (this.form.ruc_broker = this.user.account.data_billing.ruc),
                (this.form.corporateName_broker =
                    this.user.account.data_billing.corporateName),
                (this.form.fiscal_address =
                    this.user.account.data_billing.fiscal_address),
                (this.form.follow_email =
                    this.user.account.data_billing.follow_email);
            this.form.role = this.user.account.rol;
        } else if (this.rol === 'independiente' && this.user) {
            this.form.ruc = this.user.ruc;
            this.form.logo = this.user.logo;
            this.form.datosRemitente = this.user.datosRemitente;
            this.form.corporateName = this.user.corporateName;
            this.form.departamentoId = this.user.departamentoId;
            this.form.provinciaId = this.user.provinciaId;
            this.form.distritoId = this.user.distritoId;
            this.form.urbanizacionId = this.user.urbanizacionId;
            this.form.direccion = this.user.direccion;
            this.form.interior = this.user.interior;
            this.form.accountNumber = this.user.accountNumber;
            if (this.user.accounts && this.user.accounts.length > 0) {
                this.form.accounts = this.user.accounts;
            }
        } else if (this.rol === 'asociado') {
            this.asociado();
        }
    }

    async asociado() {
        const data = await membershipRequestModule.getMemberShipAccount();
        if (data.length > 0) {
            this.memberShipRequest = data[0];
            this.form.logo = this.memberShipRequest.to.logo;
            this.form.ruc = this.memberShipRequest.account.data_billing.ruc;
            this.form.corporateName =
                this.memberShipRequest.account.data_billing.corporateName;
            this.form.datosRemitente = this.memberShipRequest.to.datosRemitente;
            this.statusMemberShip = this.memberShipRequest.status;
            (this.form.mvcs_pj =
                this.memberShipRequest.account.data_billing.MVCSJ),
                (this.form.ruc_broker =
                    this.memberShipRequest.account.data_billing.ruc),
                (this.form.corporateName_broker =
                    this.memberShipRequest.account.data_billing.corporateName),
                (this.form.fiscal_address =
                    this.memberShipRequest.account.data_billing.fiscal_address),
                (this.form.follow_email =
                    this.memberShipRequest.account.data_billing.follow_email);
        }
    }
}
