























































































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { ubigeoModule } from '@/store/modules/ubigeo';

@Component
export default class FormAddress extends Vue {
    @PropSync('data', { required: true }) form!: Record<string, number>;
    @Prop({ type: String, default: 'distritoId' }) districtKey!: string;
    @Prop({ type: String, default: 'provinciaId' }) provinceKey!: string;
    @Prop({ type: String, default: 'departamentoId' }) departmentKey!: string;
    @Prop({ type: String, default: 'urbanizacionId' }) urbanizationKey!: string;
    @Prop({ type: String, default: 'direccion' }) addressKey!: string;

    get departments() {
        return ubigeoModule.departments;
    }

    get provinces() {
        return ubigeoModule.provinces;
    }

    get districts() {
        return ubigeoModule.districts;
    }

    get urbanizations() {
        return ubigeoModule.urbanizations;
    }

    async mounted() {
        await ubigeoModule.getDepartments();

        if (this.form[this.departmentKey]) {
            await ubigeoModule.getProvinces(this.form[this.departmentKey]);
        }

        if (this.form[this.provinceKey]) {
            await ubigeoModule.getDistricts(this.form[this.provinceKey]);
        }

        if (this.form[this.districtKey]) {
            await ubigeoModule.getUrbanizations(this.form[this.districtKey]);
        }
    }

    async changeDepartment(value: number) {
        this.$set(this.form, this.districtKey, '');
        this.$set(this.form, this.provinceKey, '');
        this.$set(this.form, this.urbanizationKey, '');
        await ubigeoModule.getProvinces(value);
    }

    async changeProvince(value: number) {
        this.$set(this.form, this.urbanizationKey, '');
        this.$set(this.form, this.districtKey, '');
        await ubigeoModule.getDistricts(value);
    }

    async changeDistrict(value: number) {
        this.$set(this.form, this.urbanizationKey, '');
        await ubigeoModule.getUrbanizations(value);
    }
}
