import { VuexModule, Module, Action } from 'vuex-class-modules';
import Axios from 'axios';
import store from '@/store';
import { Location } from '@/interfaces/location';

@Module({ generateMutationSetters: true })
export class UbigeoModule extends VuexModule {
    departments: Location[] = [];
    provinces: Location[] = [];
    districts: Location[] = [];
    urbanizations: Location[] = [];

    @Action
    async getDepartments() {
        if (this.departments.length < 1) {
            const { data } = await Axios.get('ubigeo/departamentos');
            this.departments = data;
        }
    }

    @Action
    async getProvinces(departamentoId?: number) {
        const { data } = await Axios.get('ubigeo/provincias', {
            params: { idDepartamento: departamentoId }
        });
        this.provinces = data;
        this.districts = [];
        this.urbanizations = [];
    }

    @Action
    async getDistricts(provinciaId?: number) {
        const { data } = await Axios.get('ubigeo/distritos', {
            params: { provinciaId }
        });
        this.districts = data;
        this.urbanizations = [];
    }

    @Action
    async getUrbanizations(distritoId?: number) {
        const { data } = await Axios.get('ubigeo/urbanizacion', {
            params: { distritoId }
        });
        this.urbanizations = data;
    }
}
export const ubigeoModule = new UbigeoModule({
    store,
    name: 'ubigeo'
});
