



























































interface Account {
    bank: string;
    accountNumber: string;
    cci: string;
}

import { Component, Prop, Vue } from 'vue-property-decorator';
import AccountInput from '@/components/AccountInput.vue';

@Component({
    components: { AccountInput }
})
export default class BankDetail extends Vue {
    @Prop() value!: { accounts: Account[] };

    penAccount = false;
    dollarAccount = false;

    get form() {
        return this.value;
    }

    set form(value: { accounts: Account[] }) {
        this.$emit('input', value);
    }
}
